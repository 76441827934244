<template>
    <div id="eneReportGroupPoint" ref="eneReportGroupPoint">
        <a-modal v-model="visible" :title="title"  :width="1024" :getContainer="() => this.$refs.eneReportGroupPoint" @ok="onSubmit">
            <div v-loading="loading" :element-loading-text="$t('alarm.a0')">
                <div style="display:flex;justify-content:space-between;padding:5px;">
                    <h2 style="line-height:32px;">{{rptGroupName}}</h2>
                    <!-- 
                        <span style="line-height:32px;color:#cccccc;">日报/月报/年报可设置的计量点最大数量为32个。</span> 
                        pointNumberTips
                        $t('energy.setting.reportGr.numberTips')
                    -->
                    <span style="line-height:32px;color:#cccccc;">{{ pointNumberTips}}</span>
                </div>
                <div>
                    <a-breadcrumb >
                        <a-breadcrumb-item  v-for="item in selClients" :key="item.key">{{item.name}}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <div style="display:flex;justify-content: flex-start;padding:5px;">
                     <!-- <div style="display:flex;justify-content: flex-start;padding:3px;"> -->
                            
                            <!-- <span style="line-height:32px;">{{ $t("energy.enedata075") }}：</span>
                            <a-radio-group v-model="treeLevel" button-style="solid" @change="search">
                                <a-radio-button value="1">{{ $t("energy.enedata076") }}</a-radio-button>
                                <a-radio-button value="2">{{ $t("energy.enedata077") }}</a-radio-button>
                                <a-radio-button value="3">{{ $t("energy.enedata078") }}</a-radio-button>
                            </a-radio-group>-->
                            <!-- 所属 -->
                            <span style="line-height:32px;margin-left:5px;">{{ $t("energy.enedata199") }}：</span> 
                            <a-cascader   v-model="mclients" style="width:300px;margin-left:5px;" v-if="modeno==19||modeno==20"
                                :field-names="{ label: 'name', value: 'key', children: 'children' }" :options="clients" 
                                change-on-select  :placeholder="grtips"  :title="grtips" @change="clientSelect"/>
                            <!-- <span style="line-height:32px;margin-left:5px;">Gr：</span>  -->
                            <a-cascader   v-model="grs" style="width:180px;margin-left:5px;" v-else
                                :field-names="{ label: 'text', value: 'no', children: 'children' }" :options="grList" 
                                change-on-select  :placeholder="grtips"  :title="grtips" />
                                <!-- @change="pointGrChange" -->
                    <!-- </div>
                    <div style="display:flex;justify-content: flex-start;padding:3px;"> -->
                         <span style="line-height:32px;margin-left:5px;">{{$t('energy.setting.reportGr.kikiName')}}：</span>
                            <a-input v-model="kikiName" style="width:150px;margin-left:5px;margin-right:5px;"  :placeholder="pointNameTips2"/>
                            <span style="line-height:32px;margin-left:5px;">{{$t('energy.setting.reportGr.pointName')}}：</span>
                            <a-input v-model="name" style="width:150px;margin-left:5px;margin-right:5px;"  :placeholder="pointNameTips"/>
                    <!-- </div> -->
                    <a-button type="primary" @click="search">{{$t('energy.search')}}</a-button>
                </div>
                <div>
                     <my-rpt-group-points :data="pointList" :Yscroll="Yscroll1" :checkType="checkType" 
                     :page="pagination1" :total="total"
                     :fatherChangeMethod="setAddSelectedPointIds" :fatherQueryMethod="pageSearch"></my-rpt-group-points>
                </div>
                <div style="text-align:center;margin:5px;">

                    <a-button type="primary" @click="addPoints">{{$t('energy.add')}}</a-button>
                     
                    <a-button type="default" @click="removePoints" style="margin-left:20px;">{{$t('energy.remove')}}</a-button>
                </div>

                <div>
                    <my-rpt-group-points :data="selectedPoints"  :Yscroll="Yscroll2" :checkType="checkType" :page="pagination2" 
                    :fatherChangeMethod="setRemoveSelectedPointIds" :total="selectedPoints?selectedPoints.length:0"
                    ></my-rpt-group-points>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import CBuildReportGroupPointTableSelect from "./eneBuildReportGroupPointSelectTables.vue";
import {getBuildReportGrPointList , saveEneReportGrPointList}  from "../../../../api/energy";

export default {
    name: 'SEneReportGroupPoint',
    props: {
        rptGroupId:{
            type:Number,
        },
        rptGroupName:{
            type:String,
        },
        modeno:{
            type:Number,
        },
        lmKind:{
            type:Number,
        },
        title: {
            type: String,
            default: '',
        },
        fatherChangeMethod: {
            type: Function,
            default: null
        },
    }, 
    data() {
        let nameTips = this.$t('energy.setting.reportGr.inputLabel').replace('${label}',this.$t('energy.setting.reportGr.pointName')) ;
        let nameTips2 = this.$t('energy.setting.reportGr.inputLabel').replace('${label}',this.$t('energy.setting.reportGr.kikiName')) ;
        return {
            visible:false,
            loading:false,
            selectedPoints:[],
            pointList:[],
            grList:[],
            clients:[],
            grs:[],
            mclients:[],
            selClients:[],
            clientKeys:[],
            name:'',
            kikiName:'',
            Yscroll1:{x: 1000,y:200},
            Yscroll2:{x: 1000,y:200},
            addSelectedPointIds:[],
            removeSelectedPointIds:[],
            checkType:'checkbox',
            pagination1: {
            },
            pagination2: {
            },
            registMax:32,
            grtips:this.$t('energy.placeSelect')+'Gr',
            pointNameTips:nameTips,
            pointNameTips2:nameTips2,
            treeLevel:'3',
            total:0,
            pointNumberTips:'',
        };  
    },
    watch:{
        "$parent.pointSelectVisible":{
            handler(val){
                if(val){              
                    this.visible = val;
                    if(this.modeno==19 || this.modeno==20){
                        this.treeLevel = '1';
                        this.mclients.push(this.$store.getters.clientId+"@@client@@1");
                        this.mclients.push(this.$store.getters.sitegrId+"@@sitegr@@1");
                        this.mclients.push(this.$route.query.id+"@@site@@1");
                        this.clientKeys = this.mclients;
                        this.registMax = 12;
                        this.pointNumberTips = this.$t('energy.setting.reportGr.trendTips');
                        this.getEnePointList(0,0,100);
                    }else{
                        this.registMax = 32;
                        this.pointNumberTips = this.$t('energy.setting.reportGr.numberTips');
                        this.treeLevel = '3';
                        this.getEnePointList(0,0,0);
                    }
                    
                }
            },
            deep:true,
            immediate:true,
        },
        "visible":{
            handler(val){
                if(!val){
                    this.$parent.pointSelectVisible =  val;
                    this.name = '';
                    this.kikiName = '';
                    this.treeLevel = '3';
                    this.grs = [];
                    this.selectedPoints = [];
                    this.pointList = [];
                    this.selClients=[];
                    this.mclients=[];
                    this.clientKeys=[];
                }   
            },
            deep:true,
            immediate:true,
        },
    },
    methods: {
        clientSelect(value, selectedOptions){
            console.log('value',value);
            console.log('selectedOptions',selectedOptions);
            this.selClients = selectedOptions;
            this.clientKeys = value;
            
        },
        onSubmit(){
            let title = this.$t("energy.setting.reportGr.saveMP");
            this.$confirm({
                title: title,
                centered: true,
                onOk: () => {
                    let params =  {
                        action:0,
                        clientId: this.$store.getters.clientId,
                        siteId: this.$route.query.id,
                        sitegrId: this.$store.getters.sitegrId,
                        tenantId: 0,
                        modeno: this.modeno,
                        reportGroupNo: this.rptGroupId,
                        selPointList: this.selectedPoints,
                    };
                    console.log("onSubmit Params",params);
                    this.loading = true;
                    saveEneReportGrPointList(params)
                    .then((res) => {
                        console.log("onSubmit Result",res);
                        this.loading = false;
                        if(res && (res.errorCode == '00' || res.errorCode == '03')){
                            this.$parent.rptGroup.rptGroupList = params.selPointList;
                            // this.$message.success(this.$t("energy.setting.reportGr.saveSuce"));
                            this.$message.success(res.msg);
                            this.visible = false;
                        } else {
                            // this.$message.error('数据保存失败');
                            // this.$message.error(this.$t("energy.setting.reportGr.saveFail"));
                             this.$message.error(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                },
            });
            
            
        },
        pointGrChange(e){
           if(this.modeno==19 || this.modeno==20){
                this.getEnePointList(1,0,100);
            }else{
                this.getEnePointList(1,0,0);
            }
        },
        search(){
            if(this.modeno==19 || this.modeno==20){
                this.getEnePointList(1,0,100);
            }else{
                this.getEnePointList(1,0,0);
            }
        },
        pageSearch(page,size){
            if(this.modeno==19 || this.modeno==20){
                this.getEnePointList(1,page,size);
            }
        },
        addPoints(){
            let len  = this.addSelectedPointIds.length;
            if(len<1){
                this.$message.error(this.$t("energy.setting.reportGr.addMp"));
                //this.$message.error('请选择需要添加的计量点。');
                return;
            }
            if((len+this.selectedPoints.length)>this.registMax){
                this.$message.error(this.pointNumberTips);
                //this.$t("energy.setting.reportGr.limitMp")
                //this.$message.error('所选择的计量点数量不可超过'+this.registMax+"。");
                return;
            }
            let points = new Array();
            let unselPoints = new Array();
            this.pointList.forEach(item =>{
                let bol = false;
                for(let i=0;i<len;i++){
                    // let id = parseInt(this.addSelectedPointIds[i]);
                    let id = this.addSelectedPointIds[i];
                    if(item.key==id){
                        points.push(item);
                        bol = true;
                    }
                }
                if(!bol){
                    unselPoints.push(item);
                }
            });
            /***begin 增加判断是否非同一等级的计量参数被分入同一分组 added by li_yj on 2022-5-10***/
            //判断要添加的计量点是否同一等级
            // let selectedPointsTemp = [... this.selectedPoints,...points];
            // let existDiffLevel=false;
            // for(let i=0;i<selectedPointsTemp.length;i++){
            //     for(let j=i+1;j<selectedPointsTemp.length;j++){
            //         if(selectedPointsTemp[i].gr1!=selectedPointsTemp[j].gr1||selectedPointsTemp[i].gr2!=selectedPointsTemp[j].gr2
            //         ||selectedPointsTemp[i].gr13!=selectedPointsTemp[j].gr3){
            //             existDiffLevel=true;
            //             break;
            //         }
            //     }
            //     if(existDiffLevel)
            //         break;
            // }
            // if(existDiffLevel){
            //     let ref=this;
            //     this.$confirm({ 
            //         // 询问是或否确定删除
            //         title: this.$t("energy.setting.reportGr.groupingLabel"),
            //         centered: true,
            //         okText: this.$t("general.a8"),
            //         okType: 'danger',
            //         cancelText: this.$t("general.a9"),                
            //         onOk() {
            //             ref.pointList = unselPoints;
            //             ref.selectedPoints = [... ref.selectedPoints,...points];
            //             ref.selectedPoints.sort((a, b) => a.tagPoint.localeCompare(b.tagPoint));
            //         },
            //         oncancel() {
            //             // ref.isContinue=false;
            //         }
            //     });                
            // }
            // else{
            // }
            /***end 增加判断是否非同一等级的计量参数被分入同一分组 added by li_yj on 2022-5-10***/
            this.pointList = unselPoints;
            this.selectedPoints = [... this.selectedPoints,...points];
            this.selectedPoints.sort((a, b) => a.tagPoint.localeCompare(b.tagPoint));

        },
        openDelConfirm(name) {
            return this.$confirm(`此操作将删除 ${name}, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
        },
        removePoints(){
            let len  = this.removeSelectedPointIds.length;
            if(len<1){
                 this.$message.error(this.$t("energy.setting.reportGr.removeMp"));
                 return;
            }

            let points = new Array();
            let selPoints = new Array();
            this.selectedPoints.forEach(item =>{
                let bol = false;
                for(let i=0;i<len;i++){
                    // let id = parseInt(this.removeSelectedPointIds[i]);
                    let id = this.removeSelectedPointIds[i];
                    if(item.key==id){
                        points.push(item);
                        bol = true;
                    }
                }
                if(!bol){
                    selPoints.push(item);
                }
            });
            this.pointList = [...this.pointList,...points];
            this.pointList.sort((a, b) => a.tagPoint.localeCompare(b.tagPoint));
            this.selectedPoints = selPoints;

        },
        setSelClientOption(list,key){
            if(list){
                for (let i = 0; i < list.length; i++) {
                    const element = list[i];
                    if(element.key == key){
                        this.selClients.push(element)
                        break;
                    }else{
                        if(element.children){
                            this.setSelClientOption(element.children,key)
                        }
                    }
                }
            }
        },
        getEnePointList(action,page,size){
            let gr1 = this.grs.length>0?this.grs[0]:"";
            let gr2 = this.grs.length>1?this.grs[1]:"";
            let gr3 = this.grs.length>2?this.grs[2]:"";
            let lvl = parseInt(this.treeLevel);
            let params =  {
                action:action,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId:   this.$route.query.id,
                tenantId: 0,
                modeno: this.modeno,
                lmKind:this.lmKind,
                groupNo: this.rptGroupId,
                gr1: gr1,
                gr2: gr2,
                gr3: gr3,
                signalName: this.name,
                treeLevel: lvl,
                pageNum:page,
                pageSize:size,
                clientKeys:this.clientKeys,
                kikiName:this.kikiName,
            };
            console.log("getEnePointList Params",params);
            this.loading = true;
            getBuildReportGrPointList(params)
            .then((res) => {
                console.log("getEnePointList Result",res);
                this.loading = false;
                if(res && res.errorCode == '00'){
                    this.pointList = res.data.pointList;
                    this.total = res.data.total;
                    if(res.data.action==0){
                        this.selectedPoints = res.data.selPointList;
                        this.registMax = res.data.registMax;
                        this.grList = res.data.grlist;
                        this.clients = res.data.clients;
                        if( this.clients && this.mclients.length>0 && this.selClients.length ==0){
                            for (let i = 0; i < this.mclients.length; i++) {
                                let key = this.mclients[i];
                                this.setSelClientOption( this.clients,key);
                            }
                             console.log("SelClients Result",this.selClients);
                        }
                    }
                } else {
                   this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
                }
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        setAddSelectedPointIds(selectedRowKeys){
            this.addSelectedPointIds =selectedRowKeys;
             console.log('setAddSelectedPointIds',this.addSelectedPointIds);
        },
        setRemoveSelectedPointIds(selectedRowKeys){
            this.removeSelectedPointIds = selectedRowKeys;
            console.log('setRemoveSelectedPointIds',this.removeSelectedPointIds);
        },
    },
    components: {
        'my-rpt-group-points':CBuildReportGroupPointTableSelect,
    },
}
</script>
<style scoped>
div /deep/.ant-modal-body{
    padding: 5px 10px;
}
</style>